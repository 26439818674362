import React from 'react';
import Script from 'next/script';
import { BS_TRACKING_ID } from 'helpers/constants/BigSurAnalytics';

const BSSnippet = () => {
  return (
    <>
      <Script async src="https://bigsur.ai/plugin/js/static.js" strategy="afterInteractive" />
      <Script type="text/javascript" id="big-sur-ai" strategy="afterInteractive">
        {`
          function i(){window.bigSur.init('${BS_TRACKING_ID}')}window.bigSur?i():window.addEventListener("bsai::BigSurInitialized",i);
        `}
        {`
          function checkoutInitiated(arguments){}
          function cartUpdated(arguments){}
          function orderPlaced(arguments){}
        `}
      </Script>
    </>
  );
};

export default BSSnippet;
